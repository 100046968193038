<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
      offset-lg="4"
      offset-md="4"
      offset-xl="4"
    >
      <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
        <v-card-title>Alphalist</v-card-title>
        <v-card-text>
          <p class="mb-4 text-5">
            <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
          <v-form>

            <!-- view As -->
            <v-radio-group
              v-model="viewAs"
              row
              class="pt-0 mt-0"
            >
              <v-radio
                label="PDF"
                value="pdf"
              ></v-radio>
              <v-radio
                label="Excel"
                value="excel"
              ></v-radio>
            </v-radio-group>

            <!-- schedule -->
            <v-autocomplete
              v-model="schedule"
              outlined
              dense
              clearable
              label="Schedule"
              item-value="id"
              item-text="name"
              :items="['1', '2']"
              :class="formErrorFields.scheduleErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.scheduleErrorMessage"
              @change="formError().remove('schedule', formErrorFields)"
            ></v-autocomplete>

            <!-- date -->
            <v-dialog
              ref="dialog"
              v-model="modaldate"
              :return-value.sync="dateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :loading="dateLoading"
                  v-model="date"
                  label="Date"
                  outlined
                  dense
                  placeholder="Date"
                  v-bind="attrs"
                  v-on="on"
                  autocomplete="off"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.dateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.dateErrorMessage"

                  @click="formError().remove('date', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                scrollable
                type="month">
                <v-btn text color="primary" @click="modaldate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modaldate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-btn
              color="primary"
              :loading="loading"
              @click="generate">
              Generate
            </v-btn>
            <v-btn
              outlined
              class="mx-2"
              @click="reset"
            >
              Reset
            </v-btn>
          </v-form>
        </v-card-text>

        <snackbar
          v-if="message.length > 0"
          :show="message.length > 0"
          :text="message"
          :color="messageStatus"
          @close="message=''"
        >
        </snackbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  metaInfo: {
    title: 'Alpha List',
  },

  components: {
    snackbar,
  },

  setup() {
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const dateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 7))
    const dateLoading = ref(false)
    const date = ref('')
    const modaldate = ref(null)
    const viewAs = ref('pdf')
    const schedule = ref('')
    const formLoading = ref(false)
    const formErrorFields = ref({
      dateErrorMessage: '',
      scheduleErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      const formData = {
        schedule: schedule.value.toLowerCase(),
        date: date.value,
        viewAs: viewAs.value,
      }

      let mimetype = ''
      if (viewAs.value === 'pdf') {
        mimetype = 'application/pdf'
      } else {
        mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }

      await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/alphalist/${fnx.base64_encode(formData)}`, reportData, formLoading, error)

      if (error.value.data !== undefined) {
        if (error.value.status === 200) {
          message.value = error.value.statusText
          messageStatus.value = 'success'
        } else {
          message.value = error.value.statusText
          messageStatus.value = 'error'
        }
      } else {
        message.value = ''
        messageStatus.value = ''

        const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
        window.open(url, '_blank')
      }
    }

    const reset = () => {
      viewAs.value = 'pdf'
      schedule.value = ''
      date.value = ''
    }

    return {
      dateLoading,
      date,
      modaldate,
      dateValue,
      viewAs,
      schedule,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
    }
  },
}
</script>
